import CheckboxSelectAll from 'stimulus-checkbox-select-all'
import { useEventListener } from 'stimulus-library'
import { patch } from '@rails/request.js'

/**
 * Stimulus controller to manage a group of checkboxes, whose state is stored on the server.
 * The server-side storage is an opt-in feature, enabled by setting the `url` value.
 * The server-side storage is updated when a checkbox is clicked, or when the "select all" checkbox is toggled.
 * The server can respond with a turbo stream action, and it will update the DOM accordingly.
 *
 * @extends CheckboxSelectAll
 *
 * Note that this class relies upon turbo-permanent data attribute to prevent the checked state of checkboxes from being
 * cleared by Turbo updates to those table rows. The streamed row needs to also have the turbo-permanent data attribute
 * in order for the checked state to be preserved.
 *
 * Server-side storage of the selected items can interfere with activity in other tabs because the Rails
 * session is not distinct per browser tab. To mitigate this, we generate a unique "Selection Session ID" for each tab,
 * and send it along with the request as a custom header. This information is used on the server to separate the
 * selections made in different tabs.
 */

const selectionSessionIdHeader = 'X-Selection-Session-Id'
const browserSessionStorageKey = 'ssp-ssid'

export default class TurboCheckboxController extends CheckboxSelectAll {
  static values = {
    url: String,
    payload: Object
  }

  connect() {
    super.connect()
    this.initializeSelectionSession();
    useEventListener(this, window, 'turbo-checkbox:clear_selection', this.forceClearAll)
    useEventListener(this, document.documentElement, 'turbo:before-fetch-request', this.injectHeaderForOtherTurboRequests);
  }

  /**
   * Listeners are added to each checkbox individually (rather than adding them to the collection on #connect) because
   * checkboxes with the turbo-permanent data attribute may not be present at controller connection time. This ensures
   * all checkboxes (permanent or otherwise) have listeners attached when they are added to the DOM.
   */
  checkboxTargetConnected(checkbox) {
    super.checkboxTargetConnected(checkbox)
    useEventListener(this, checkbox, 'change', this.checkboxClicked)
  }

  /**
   * Listen for the "turbo-checkbox:clear_selection" event, and clear all checkboxes.
   */
  forceClearAll() {
    this.checkboxAllTarget.checked = false
    this.clearSelection()
  }

  toggle(event) {
    super.toggle(event)
    event.target.checked ?
      this.selectAll() :
      this.clearSelection()
  }

  clearSelection() {
    this.checkboxTargets.forEach(cb => this.removeTurboUpdateGuard(cb))
    this.serverClearSelection()
  }

  selectAll() {
    this.checkboxTargets.forEach(cb => this.addTurboUpdateGuard(cb))
    this.serverSelectAll()
  }

  serverSelectAll() {
    const checkedIds = this.checked.map(checkbox => checkbox.value)
    this.updateServer({id: checkedIds, operation: 'add'})
  }

  serverClearSelection() {
    this.updateServer({ operation: 'clear_all' })
  }

  checkboxClicked(event) {
    if (event.target.checked) {
      this.addTurboUpdateGuard(event.target)
      this.updateServer({ id: event.target.value, operation: 'add' })
    } else {
      this.removeTurboUpdateGuard(event.target)
      this.updateServer({ id: event.target.value, operation: 'remove' })
    }
  }

  /**
   * Guard the value of the checkbox from being cleared by the Turbo update operations.
   * Preserve the checked state of the checkbox even when the DOM is updated.
   */
  addTurboUpdateGuard(cb) {
    cb.dataset.turboPermanent = 'true'
  }

  /**
   * Remove the guard on the value of the checkbox, so that the checkbox can be cleared by the Turbo update operations.
   */
  removeTurboUpdateGuard(cb) {
    delete cb.dataset.turboPermanent
  }

  async updateServer(data) {
    // server-side storage of the selected items is an opt-in feature;
    // if the URL value is not set, we skip the server interaction
    if (!this.hasUrlValue) {
      return
    }

    const payload = Object.assign(this.payloadValue, data)
    await patch(this.urlValue, { body: JSON.stringify(payload), headers: this.headers })
  }

  /**
   * Inject the selection session ID header into all OTHER Turbo fetch requests originating on the page, as these may
   * depend upon the selections already made (eg, bulk update operations).
   * @param event the turbo:before-fetch-request event
   */
  injectHeaderForOtherTurboRequests(event) {
    Object.assign(event.detail.fetchOptions.headers, this.headers)
  }

  get headers() {
    return { [selectionSessionIdHeader]: this.selectionSessionId() }
  }

  selectionSessionId() {
    return sessionStorage.getItem(browserSessionStorageKey);
  }

  initializeSelectionSession() {
    sessionStorage.setItem(browserSessionStorageKey, this.generateUUID());
  }

  generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = Math.random() * 16 | 0;
      const v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
}