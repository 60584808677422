import { Controller } from '@hotwired/stimulus'
import { useEventListener } from 'stimulus-library'

export default class InjectFormDataController extends Controller {
  static values =
    {
      attributeName: String,
      targetSelector: String
    }

  connect() {
    super.connect()
    this.form = this.element.closest('form')
    useEventListener(this, this.form, 'turbo:submit-start', this.submit)
  }

  submit(event) {
    const targetValue = document.querySelector(this.targetSelectorValue)?.value;
    if (targetValue) {
      event.detail.formSubmission.fetchRequest.body.append(this.attributeNameValue, targetValue)
    }
  }
}